export const carouselImages = [
    {
        "imagen": "../assets/imgs/1.jpg",
        "url": "https://verificacionvehicular.hidalgo.gob.mx" 
    },
    {
        "imagen": "../assets/imgs/2.jpg",
        "url": "https://bitacora.semarnath.gob.mx/"
    },
    {
        "imagen": "https://tesorosnaturales.semarnath.gob.mx/images/WALLPAPER_ESCRITORIO-06.jpg",
        "url": "" 
    },
    {
        "imagen": "https://tesorosnaturales.semarnath.gob.mx/images/WALLPAPER_ESCRITORIO-02.jpg",
        "url": ""
    },
    {
        "imagen": "https://tesorosnaturales.semarnath.gob.mx/images/WALLPAPER_ESCRITORIO_01.jpg",
        "url": ""
    },

    {
        "imagen": "https://tesorosnaturales.semarnath.gob.mx/images/WALLPAPER_ESCRITORIO-08.jpg",
        "url": ""
    },

]