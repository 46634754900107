export const adquisicionesArray = [

    {
        "pdf": '/pdf/paaas18.pdf',
        "nombre": '2018',

    },

    {
        "pdf": '/pdf/paaas19.pdf',
        "nombre": '2019',

    },

    {
        "pdf": '/pdf/paaas20.pdf',
        "nombre": '2020',

    },

    {
        "pdf": '/pdf/paaas21.pdf',
        "nombre": '2021',

    },
     {
         "pdf": '/pdf/paaas22.pdf',
        "nombre": '2022',

    },
     {
         "pdf": '/pdf/paaas23.pdf',
        "nombre": '2023',

    },

    {
        "pdf": '/pdf/paaas24.pdf',
        "nombre": '2024',

    },
    {
        "pdf": '/pdf/paaas25.pdf',
        "nombre": '2025',

    },

]