export const tramites_item = [
	{
		"tramite": 'https://ruts.hidalgo.gob.mx/ver/1536',
		"nombre": 'Autorización en materia de Impacto Ambiental del transporte de residuos de manejo especial generados en procesos industriales',
		"descripcion": 'Descripcion: Personas Físicas y Morales que realizan la recolección y transporte de Residuos de Manejo Especial dentro del Estado de Hidalgo.',

	},
	{
		"tramite": 'https://ruts.hidalgo.gob.mx/ver/5575',
		"nombre": 'Ampliación de Periodo de Verificación Vehícular Extemporanea',
		"descripcion": 'Descripcion: Tramite una ampliación de periodo para acreditar la verificación vehícular del periodo correspondiente',
	},
	{
		"tramite": 'https://ruts.hidalgo.gob.mx/ver/1595',
		"nombre": 'Autorización de Bitácora de Control para Residuos de Manejo Especial',
		"descripcion": 'Descripcion: Llevar el registro del volumen de residuos de manejo especial que generan en sus procesos de producción los generadores de manejo especial.',

	},
	{
		"tramite": 'https://ruts.hidalgo.gob.mx/ver/694',
		"nombre": 'Autorregulación Ambiental',
		"descripcion": 'Descripcion: Los productores, empresas u organizaciones empresariales que cumplan con la normatividad vigente en materia ambiental, podrán desarrollar procesos voluntarios de autorregulación ambiental, a través de los cuales mejoren su desempeño ecológico y se comprometan a cumplir mayores niveles, metas o beneficios en materia de protección ambiental. ',

	},

	{
		"tramite": 'https://ruts.hidalgo.gob.mx/ver/1596',
		"nombre": 'Aprobación del Plan de Manejo de Residuos de Manejo Especial',
		"descripcion": 'Descripcion: Establece que las personas físicas o morales generadoras de residuos de manejo especial, deberán presentar sus planes y programas de manejo que definan acciones y medidas para la prevención, control, minimización, reutilización y reciclaje, así como su informe de avances y logros de forma semestral, cuyo objetivo es minimizar la generación y maximizar la valorización y aprovechamiento de los residuos.',

	},

	{
		"tramite": 'https://ruts.hidalgo.gob.mx/ver/6664',
		"nombre": 'Autorización para la Operación de Sitios de Disposición final para Residuos Provenientes de la Construcción',
		"descripcion": 'Dar a conocer al ciudadano las obligaciones ambientales que debe cumplir un sitio de disposición final de residuos provenientes de la construcción y la factibilidad del mismo en congruencia con el Programa de Ordenamiento Ecológico del Territorio aplicable. La implementación de la evaluación de los sitios para la disposición final de residuos provenientes de la construcción para el Estado resulta a partir de la Ley para la Protección al Ambiente del Estado de Hidalgo, instrumento normativo que respalda la protección al ambiente y dispone en los artículos 6 fracción XXI, 159 y 160, así como en los artículos 4 y 25 de la Ley de Prevención y Gestión Integral de Residuos del Estado de Hidalgo.',

	},

	
	{
		"tramite": 'https://ruts.hidalgo.gob.mx/ver/1489',
		"nombre": 'Autorización de cambio de titular del centro de verificación vehicular',
		"descripcion": 'Descripcion: Es la factibilidad técnica, legal y administrativa soportada en la legislación ambiental, Decreto de Creación de esta dependencia y Lineamientos para el Funcionamiento de la Red Estatal de Centros de Verificación Vehicular, para la autorización de cambio de titularidad de la Autorización del Centro de Verificación Vehicular',

	},
	{
		"tramite": 'https://ruts.hidalgo.gob.mx/ver/1491',
		"nombre": 'Autorización de nuevas líneas de verificación vehicular',
		"descripcion": 'Descripcion: Es la factibilidad técnica, legal y administrativa soportada en la legislación ambiental, Decreto de Creación de esta dependencia y Lineamientos para el Funcionamiento de la Red Estatal de Centros de Verificación Vehicular o Unidades de Verificación Vehicular, para la autorización de cambio de titularidad de la Autorización del Centro de Verificación Vehicular o Unidad de Verificación Vehicular.',

	},

	{
		"tramite": 'https://ruts.hidalgo.gob.mx/ver/750',
		"nombre": 'Autorización de cambio de domicilio del centro de verificación vehicular fijo',
		"descripcion": 'Descripcion: Es la factibilidad técnica, legal y administrativa soportada en la legislación ambiental, Decreto de Creación de esta dependencia y Lineamientos para el Funcionamiento de la Red Estatal de Centros de Verificación Vehicular o Unidades de Verificación Vehicular, para la autorización para operar los centros de verificación vehicular fijos en otro lugar dentro del mismo municipio donde se encuentra le fue emitida la autorización.',

	},

	{
		"tramite": 'https://ruts.hidalgo.gob.mx/ver/11307',
		"nombre": 'Autorización de Modificación a una Resolución en Materia de Impacto Ambiental para el Transporte de Residuos de Manejo Especial',
		"descripcion": 'La Modificación de la Autorización de Proyectos para el Transporte de Residuos de Manejo Especial, en conformidad con el artículo 37 de la Ley para la Protección al Ambiente del Estado de Hidalgo, artículo 40 fracción XV de la Ley Estatal de Derechos y artículo 22 fracción XXVIII del Reglamento Interior de la Secretaría de Medio Ambiente y Recursos Naturales de Hidalgo, tiene el propósito de actualizar la información de transportista sobre quienes se les brinda el servicio de la recolección y transporte, así como la información que avale los vehículos y los sitios de disposición final se encuentren autorizados; siempre y cuando se presenten cambios antes de la fecha en que aplique la renovación.',

	},

	{
		"tramite": 'http://ruts.hidalgo.gob.mx/ver/1532',
		"nombre": 'Bitácora de Operación de Equipos y Procesos Generadores de Emisiones a la Atmosfera',
		"descripcion": 'Los sectores de jurisdicción estatal, que por su operación y funcionamiento emitan o puedan emitir olores, gases o partículas sólidas o líquidas a la atmósfera, deberán contar con una bitácora de operación y mantenimiento de sus equipos de combustión, proceso y control, a fin de que cuenten con una herramienta de verificación que permita conocer el porcentaje de eficiencia de actividades y equipos.',

	},

	{
		"tramite": 'http://ruts.hidalgo.gob.mx/ver/6668',
		"nombre": 'Compulsa de documentos',
		"descripcion": 'Es la comparación de un documento con otro, para dar fe de que el mismo es copia fiel del original que se ha tenido a la vista.',

	},

	{
		"tramite": 'https://ruts.hidalgo.gob.mx/ver/6731',
		"nombre": 'Declaratoria de Áreas Naturales Protegidas',
		"descripcion": 'Descripcion: Solicitud de decreto de Área Natural Protegida Estatal. Certificar predios en buen estado de conservación, con gran riqueza biológica y proteger a especies amenazadas.',

	},

	{
		"tramite": 'http://ruts.hidalgo.gob.mx/ver/1520',
		"nombre": 'Dictaminación Técnica de la Cedula de Operación Anual',
		"descripcion": 'Llevar a cabo de manera anual la actualización de la información de su desempeño ambiental, a través de la presentación de la cédula de operación anual, acompañada de los estudios ambientales y planes y programas de manejo de sus efluentes contaminantes.',

	},

	{
		"tramite": 'http://ruts.hidalgo.gob.mx/ver/6730',
		"nombre": 'Donación de Planta Forestal para Reforestación',
		"descripcion": 'Apoyo de donación de planta a beneficiarios para la ejecución de obras de reforestación y restauración ecológica, Contribuir al medio ambiente con obras de restauración y reforestación que beneficien diversas zonas a nivel estatal',

	},

	{
		"tramite": 'http://ruts.hidalgo.gob.mx/ver/1531',
		"nombre": 'Expedición de Licencia Ambiental Estatal',
		"descripcion": 'El sector industrial, de servicios, bancos pétreos y productor ladrillero para obtener la Licencia Ambiental Estatal, deberán de presentar la información de sus actividades para el proceso productivo que realiza, acompañada de los requisitos estudios, análisis o planes de manejo correspondientes.',
	},

	{
		"tramite": 'http://ruts.hidalgo.gob.mx/ver/686',
		"nombre": 'Evaluación del Estudio de Riesgo Ambiental',
		"descripcion": 'De conformidad con el artículo 37 párrafo último y 46 de la Ley para la Protección al Ambiente del Estado de Hidalgo, quien realice actividades riesgosas, que por sus características no estén sujetas a la obtención de la autorización previa en materia de impacto ambiental deberán presentar un Estudio de Riesgo Ambiental.',
	},

	{
		"tramite": 'http://ruts.hidalgo.gob.mx/ver/5203',
		"nombre": 'Modificación del registro como Generador de Residuos de Manejo Especial',
		"descripcion": 'Tiene la finalidad de adicionar al Registro como Generador de Residuos de Manejo Especial, aquellos residuos que se generen por cambios derivados de los procesos industriales, actividades comerciales y de servicios, los cuales implican aumento, reducción o baja de residuos.',
	},


	{
		"tramite": 'http://ruts.hidalgo.gob.mx/ver/5204',
		"nombre": 'Modificación del Plan de Manejo de Residuos Sólidos de Manejo Especial',
		"descripcion": 'Tiene la finalidad de informar algún cambio en su proceso que implique un aumento, reducción, alta o baja de un residuo de acuerdo a lo que se refiere en el Plan de Manejo de Residuos de Manejo Especial inicial.',
	},

	{
		"tramite": 'http://ruts.hidalgo.gob.mx/ver/780',
		"nombre": 'Obtención de la Autorización de Impacto Ambiental',
		"descripcion": 'La evaluación de impacto ambiental es el procedimiento a través del cual la Secretaría evalúa y en su caso, establece las condiciones a que se sujetará la realización de las obras y actividades que realice el sector público o privado, con la finalidad de prevenir, evitar o reducir el desequilibrio ecológico o que rebasen los límites y condiciones establecidas en las disposiciones aplicables para proteger el ambiente.',

	},

	{
		"tramite": 'http://ruts.hidalgo.gob.mx/ver/702',
		"nombre": 'Obtención del Registro como generador de Residuos de Manejo Especial',
		"descripcion": 'Tiene el propósito de regular a las personas físicas y morales que posean algún establecimiento industrial, comercial y de servicio que genere residuos de manejo especial provenientes de las actividades propias para la obtención de un producto, bien o servicio, con el fin de controlar la generación y disposición final de los mismos.',

	},

	{
		"tramite": 'https://ruts.hidalgo.gob.mx/ver/698',
		"nombre": 'Opinión Técnica en Materia Ambiental',
		"descripcion": 'Documento mediante el cual se le da a conocer las obligaciones ambientales que deben cumplimentar un desarrollo y la factibilidad del mismo en congruencia con el ordenamiento ecológico del territorio.',

	},

	{
		"tramite": 'http://ruts.hidalgo.gob.mx/ver/6658',
		"nombre": 'Refrendo Anual de la Autorización de Proyectos para el Transporte de Residuos de Manejo Especial',
		"descripcion": 'Tiene el propósito de validar que la información siga siendo correcta sobre las unidades, recabando el número de empresas a quienes se les brinda el servicio de la recolección y transporte de residuos de manejo especial, así como la información que avale que los vehículos y los sitios de disposición final se encuentren autorizados.',

	},

	{
		"tramite": 'http://ruts.hidalgo.gob.mx/ver/749',
		"nombre": 'Renovación de Autorización a Centros de Verificación Vehicular',
		"descripcion": 'Es la factibilidad técnica, legal y administrativa soportada en la legislación ambiental y Lineamientos para el Funcionamiento de la Red Estatal de Centros de Verificación Vehicular o Unidades de Verificación Vehicular, para la renovación de la autorización de funcionamiento de los centros de verificación vehicular o unidad de verificación vehicular.',

	},

	{
		"tramite": 'http://ruts.hidalgo.gob.mx/ver/752',
		"nombre": 'Reposición de Certificado de Verificación Vehicular',
		"descripcion": 'En caso de robo o extravío del certificado original anterior, podrá solicitar a la Secretaría de Medio Ambiente y Recursos Naturales del Estado, la reposición de su certificado de verificación vehicular.',

	},

	{
		"tramite": 'http://ruts.hidalgo.gob.mx/ver/732',
		"nombre": 'Solicitud y ampliación de Prorroga de Verificación Vehicular',
		"descripcion": 'Identificarse como parte del personal que labora en la Unidad de Verificación Vehicular, así como es necesariolLa bitácora de operación y mantenimiento, la cual permite contar con un registro diario de la operación del Centro y/o Unidad de Verificación Vehicular, así como los incidentes presentados',

	},


	{
		"tramite": 'http://ruts.hidalgo.gob.mx/ver/11076',
		"nombre": 'Solicitud de Autorización de Bitácora y Expedición de Gafetes de Acreditación del Personal de las Unidades de Verificación Vehicular y Centros Verificación Vehicular',
		"descripcion": 'Identificarse como parte del personal que labora en el Centro de Verificación Vehicular.| La bitácora de operación y mantenimiento, la cual permite contar con un registro diario de la operación del Centro Verificación Vehicular, así como los incidentes presentados ',

	},

	{
		"tramite": 'http://ruts.hidalgo.gob.mx/ver/1486',
		"nombre": 'Verificación Vehicular para Adquisición de Holograma Exento',
		"descripcion": 'Otorgar a los propietarios de los vehículos híbridos, holograma Exento el cual los exime de la verificación vehicular de su unidad por 8 años.',

	},


	{
		"tramite": 'http://ruts.hidalgo.gob.mx/ver/6779',
		"nombre": 'Visita al Parque Nacional EL Chico',
		"descripcion": 'Área natural protegida con cuatro campamentos y un Centro de Visitantes, que incluye senderos auto-guiados, cuyo objetivo es contactar al turista con la naturaleza.',

	},

	{
		"tramite": 'http://ruts.hidalgo.gob.mx/ver/5911',
		"nombre": 'Visita Guiada al Parque Ecológico Cubitos',
		"descripcion": 'Promover la importancia del medio ambiente a través de un recorrido guiado por las diferentes áreas educativas y tecnológicas del Parque Ecológico Cubitos.',

	},

]